@font-face {
    font-family: "Ionicons";
    src: url("https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.eot?v=2.0.1");
    src:
        url("https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.eot?v=2.0.1#iefix")
            format("embedded-opentype"),
        url("https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.ttf?v=2.0.1")
            format("truetype"),
        url("https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.woff?v=2.0.1")
            format("woff"),
        url("https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.svg?v=2.0.1#Ionicons")
            format("svg");
    font-weight: normal;
    font-style: normal;
}

// _grid
.col {
    border-bottom: solid 1px var(--ice-blue);
    padding: 0 0 15px 0;
}

.row {
    border-bottom: solid 1px var(--ice-blue);

    &.on-top {
        border-bottom: none;
        border-top: solid 1px var(--ice-blue);
    }
}

.border-right {
    border-right: 1px solid var(--ice-blue);
}

.border-right-grey {
    border-right: 1px solid var(--light-grey);
}

.border-right-dashed {
    border-right: 1px dashed var(--ice-blue);
}

.border-right-dashed-grey {
    border-right: 1px dashed var(--light-grey);
}

.border-bottom-dashed {
    border-bottom: 1px dashed var(--ice-blue);
}

.border-left {
    border-left: 1px solid var(--ice-blue);
}

.border-full-dash {
    border: 1px dashed var(--ice-blue);
}

.border-bot-full {
    border-bottom: 1px solid var(--ice-blue);
}

.border-bot-full-grey {
    border-bottom: 1px solid var(--light-grey);
}

.border-top-full {
    border-top: 1px solid var(--ice-blue);
}

.border-none {
    border: none !important;
}

.grid {
    width: 100%;
    padding: 40px 5%;
}

.vert-divider {
    display: inline-block;
    width: 1px;
    height: 22px;
    border: solid 1px var(--ice-blue);
    padding: 0;
}

.inline-block {
    display: inline-block;
}

// Custom
.proj-stat {
    font-size: 17px;
}

ion-list,
ion-reorder-group {
    --background: var(--white-bg); // won't affect mobile since there is no themes
    background: var(--white-bg);
    background-color: var(--white-bg);
}

.lock {
    width: 17px;
    margin-left: 4px;
}
