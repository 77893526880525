.status-icon-outer {
    width: 22px;
    height: 22px;
    display: inline-block;
    border-radius: 50%;
    opacity: 0.2;
    position: relative;
}

.status-icon-inner {
    display: inline-block;
    position: absolute;
    width: 8px;
    height: 8px;
    margin-top: 7px;
    margin-left: -15px;
    border-radius: 50%;
}

.status-label {
    color: #6d747f;
}

.green {
    background-color: #00cb00;
}

.red {
    background-color: #ff0d29;
}

.yellow {
    background-color: #ffba00;
}

.grey {
    background-color: grey;
}
