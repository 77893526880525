// _background
// dp dont delete, please!
.bg-danger {
    background-color: var(--pastel-red);
}

.bg-success {
    background-color: var(--vibrant-green);
}

.bg-white {
    background-color: var(--white-bg);
    --background: var(--white-bg);
    background: var(--white-bg);
}

.bg-content {
    --background: var(--dark-content);
    background: var(--dark-content);
}

.bg-gray {
    background-color: var(--ice-blue);
    --background: var(--ice-blue);
}

.bg-orange {
    background-color: var(--tangerine);
}

.bg-palegray {
    background-color: var(--pale-grey) !important;
}

.success-background {
    background-color: rgba(0, 203, 0, 0.1);
}

.primary-background {
    background-color: #b3d8ff;
}

.bg-gun-metal {
    --background: #1e2022;
}

.bg-very-light-blue {
    background-color: var(--very-light-blue);
}

.bg-pale-blue {
    background: var(--pale-blue);
}

.bg-light-green {
    background-color: var(--very-light-green);
}

.bg-transparent {
    background: transparent !important;
}

.bg-white-primary {
    background-color: var(--white);
    --background: var(--white);
    background: var(--white);
}
