// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
    /** primary **/
    --ion-color-primary: #5fa3d0;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;

    /** secondary **/
    --ion-color-secondary: #30577e;
    --ion-color-secondary-rgb: 12, 209, 232;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #0bb8cc;
    --ion-color-secondary-tint: #24d6ea;

    /** tertiary **/
    --ion-color-tertiary: #7044ff;
    --ion-color-tertiary-rgb: 112, 68, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #633ce0;
    --ion-color-tertiary-tint: #7e57ff;

    /** success **/
    --ion-color-success: #95d259;
    --ion-color-success-rgb: 16, 220, 96;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #0ec254;
    --ion-color-success-tint: #28e070;

    /** warning **/
    --ion-color-warning: #ffce00;
    --ion-color-warning-rgb: 255, 206, 0;
    --ion-color-warning-contrast: #ffffff;
    --ion-color-warning-contrast-rgb: 255, 255, 255;
    --ion-color-warning-shade: #e0b500;
    --ion-color-warning-tint: #ffd31a;

    /** danger **/
    --ion-color-danger: #f04141;
    --ion-color-danger-rgb: 245, 61, 61;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #d33939;
    --ion-color-danger-tint: #f25454;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 34, 34;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #eeeeee;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    /** light **/
    --ion-color-light: #fff;
    --ion-color-light-rgb: 244, 244, 244;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    --white: white;
    --black: black;
    --gray: #abb2be;
    --blue: #0089ff;
    --green: #09af00;
    --red: #d11d31;

    --charcoal-grey: #2d3238;
    --light-grey: #d8d8d8;
    --pale-grey: #f8f9fc;
    --very-light-blue: #dfeefb;
    --gun-metal: #4e545d;
    --battleship-grey: #6d747f;
    --pale-blue: #dce3ee;
    --ice-blue: #e3e4e5;
    --vibrant-green: #00cb00;
    --tangerine: #ff9000;
    --very-light-green: #ccf5cc;
    --pale-lavender: #e9e0ff;
    --light-purple: #9065ff;
    --pastel-red: #f64242;
    --pale-pink: #ffcfd4;
    --neon-red: #ff0d29;
    --marigold: #ffba00;
    --active-blue: #236ced;
    --app-link-blue: #758fa7;

    // Menu Colors
    --menu-base-dark-blue: #1b344a;
    --menu-open-drop-down: #182d40;
    --menu-selected: #132433;
    --menu-group-label: #fff;
    --menu-border: #272b31;
    --section-border: #f0f2f4;
    --selection-color: #0089ff;

    --dark-bg: #1e2022;
    --dark-content: white;
    --white-bg: #f7f7f7;

    --black-txt: black;
    --pale-orange: #fae5c9;

    // links
    --active-link: #236ced;

    // button visuals
    --white-text: white;
    --blue-background: #0089ff;
}
