// _spacing
// If you need more of these feel free to add some

// Dimensions

$maxPercent: 100;
$percentSign: "%";
$pxSign: "px";

@mixin all-sides-spacings-to-100 {
    @for $i from 0 through 100 {
        .margin-top-#{ $i } {
            margin-top: #{$i}#{$pxSign} !important;
            --margin-top: #{$i}#{$pxSign} !important;
        }

        .margin-right-#{ $i } {
            margin-right: #{$i}#{$pxSign} !important;
            --margin-right: #{$i}#{$pxSign} !important;
        }

        .margin-bot-#{ $i } {
            margin-bottom: #{$i}#{$pxSign} !important;
            --margin-bottom: #{$i}#{$pxSign} !important;
        }

        .margin-left-#{ $i } {
            margin-left: #{$i}#{$pxSign} !important;
            --margin-left: #{$i}#{$pxSign} !important;
        }

        .pad-top-#{ $i } {
            padding-top: #{$i}#{$pxSign} !important;
            --padding-top: #{$i}#{$pxSign} !important;
        }

        .pad-right-#{ $i } {
            padding-right: #{$i}#{$pxSign} !important;
            --padding-right: #{$i}#{$pxSign} !important;
        }

        .pad-bot-#{ $i } {
            padding-bottom: #{$i}#{$pxSign} !important;
            --padding-bottom: #{$i}#{$pxSign} !important;
        }

        .pad-left-#{ $i } {
            padding-left: #{$i}#{$pxSign} !important;
            --padding-left: #{$i}#{$pxSign} !important;
        }
    }
}

@mixin ignored-left-margin-to-70 {
    @for $i from 1 through 70 {
        .margin-negative-left-#{ $i } {
            margin-left: #{(-1) * $i}#{$pxSign};
        }
    }
}

@mixin w-x {
    @for $i from 1 through 100 {
        .w-#{ $i } {
            width: #{$i}#{$percentSign} !important;
        }
    }
}

@include w-x;
@include ignored-left-margin-to-70;
@include all-sides-spacings-to-100;

.w-33 {
    width: 33%;
}

.h-0 {
    height: 0;
}

.h-100 {
    height: 100% !important;
}

.h-55 {
    height: 55%;
}

.h-90 {
    height: 90%;
}

.h-42px {
    height: 42px !important;
    line-height: 42px !important;
}

.h-100px {
    height: 100px !important;
}

.h-250 {
    height: 250px !important;
}

.min-h-30 {
    min-height: 30px;
}

.min-h-50 {
    min-height: 50px;
}

.min-h-100 {
    min-height: 100px;
}

.min-w-72 {
    min-width: 72px;
}

.max-w-100 {
    max-width: 100%;
}

.max-w-60 {
    max-width: 60%;
}

// Padding
.pad-0 {
    padding: 0 !important;
}

.pad-1 {
    padding: 1px;
}

.pad-2 {
    padding: 2px;
}

.pad-3 {
    padding: 3px;
}

.pad-4 {
    padding: 4px !important;
}

.pad-5 {
    padding: 5px;
}

.pad-8 {
    padding: 8px;
}

.pad-10 {
    padding: 10px;
}

.pad-12 {
    padding: 12px;
}

.pad-15 {
    padding: 15px !important;
    --padding: 15px !important;
}

.pad-20 {
    padding: 20px;
}

.pad-0-20 {
    padding: 0 20px;
}

.pad-30 {
    padding: 30px;
}

.pad-40 {
    padding: 40px;
}

.pad-70 {
    padding: 70px;
}

// right
.pad-right-5 {
    padding-right: 5px;
}

.pad-right-10 {
    padding-right: 10px !important;
    --pading-right: 10px;
}

.pad-right-12 {
    padding-right: 12px;
    --pading-right: 12px;
}

.pad-right-15 {
    padding-right: 15px;
}

.pad-right-20 {
    padding-right: 20px;
}

.pad-right-30 {
    padding-right: 30px;
}

.pad-right-40 {
    padding-right: 40px;
}

.pad-right-50 {
    padding-right: 50px;
}

// left
.pad-left-40 {
    padding-left: 40px;
}

.pad-left-50 {
    padding-left: 50px;
}

.--pad-start-25 {
    --padding-start: 25px !important;
}

// Bottom
.pad-bot-40 {
    --padding-bottom: 40px;
    padding-bottom: 40px;
}

.pad-bot-50 {
    --padding-bottom: 50px;
    padding-bottom: 50px;
}

// Top
.pad-top-36 {
    padding-top: 36px;
}

// Margin
.margin-0 {
    margin: 0 !important;
}

.margin-2 {
    margin: 2px;
}

.margin-7 {
    margin: 7px;
}

.margin-20 {
    margin: 20px;
}

.right-16 {
    right: 16px;
}

.margin-right-1-percent {
    margin-right: 1%;
}

.flex-1 {
    flex: 1;
}
