@import "./_partials/status";
@import "./_partials/_ionic";
@import "./_partials/_buttons";
@import "./_partials/_calendar_global";
@import "./_partials/_flex";
@import "./_partials/_grid";
@import "./_partials/_shared";
@import "./_partials/_spacing";
@import "./_partials/_text";
@import "./_partials/_modal";
@import "./_partials/background";
@import "./_partials/data-modals";

// pagination
.pagination-box {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 16px;
    border: 1px solid var(--ice-blue);
    background-color: #ffffff;
    font-weight: 500;
    color: var(--gray);
    cursor: pointer;
}

.paginator-info {
    font-size: 14px;
    color: var(--gray);
}

.pdf-entity-modal {
    background: rgba(0, 0, 0, 0.3);

    &::part(content) {
        width: 100% !important;
        background: transparent;
        -webkit-transform: translate3d(0, 0px, 0);
        transform: translate3d(0, 0px, 0);
        opacity: 1;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}

.pdf-modal-inner-wrapper::part(content) {
    width: 35%;
    margin-left: auto;
    background: var(--background);
    height: 100%;
    -webkit-box-shadow: var(--box-shadow);
    box-shadow: var(--box-shadow);

    ion-content {
        height: calc(100% - 94px);
    }
}

ion-modal.center-entity-modal {
    &::part(content) {
        width: 50% !important;
        height: 90% !important;
        right: auto;
        transform: translate(-50%, -50%);
        opacity: 1 !important;
    }
}

.multi-pdf-previewer {
    &::part(content) {
        width: 50% !important;
        height: 90vh !important;
        right: auto;
        opacity: 1 !important;
        border-radius: 14px;
    }
}

.center-seats-modal {
    border-radius: 14px;

    &::part(content) {
        max-width: 90vw;
        max-height: 90vh;
        width: 800px !important;
        height: 580px !important;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 1;
        border-radius: 14px;
    }
}

.center-wide-modal {
    &::part(content) {
        width: 80% !important;
        height: 90% !important;
        right: auto;
        transform: translate(-50%, -50%);
    }
}

.expandable-center-modal {
    &.calendar-view::part(content) {
        max-width: 400px !important;
        height: auto !important;
    }

    &::part(content) {
        max-width: 500px;
        max-height: 80vh;
        height: auto !important;
        --height: auto !important;
        width: 100% !important;
        right: auto !important;
        opacity: 1 !important;
        overflow-y: auto;
        transform: translate(-50%, -50%);
        border-radius: 8px;

        .ion-page {
            position: relative;
            display: block;
            contain: content;

            .inner-content {
                max-height: 80vh;
                overflow: auto;
            }
        }
    }
}

.center-modal {
    &.middle-modal::part(content) {
        max-width: 600px !important;
        max-height: 330px !important;
    }

    &.approve-attached-modal::part(content) {
        max-width: 600px !important;
        max-height: 370px !important;
    }

    &.approve-not-attached-modal::part(content) {
        max-width: 600px !important;
        max-height: 420px !important;
    }

    &.form-edit-modal::part(content) {
        max-height: 90vh;
        max-width: 90vw;
        height: 810px !important;
        width: 1500px !important;
    }

    &::part(content) {
        width: 100% !important;
        max-width: 500px;
        height: 90% !important;
        right: auto;
        transform: translate(-50%, -50%);
        border-radius: 8px;
    }
}

.edit-form-modal {
    &::part(content) {
        background: transparent !important;
        right: auto;
        transform: translate(-50%, -50%);
        border-radius: 8px;
        box-shadow: none !important;
    }
}

.center-narrow-modal {
    &::part(content) {
        width: 25% !important;
        height: 50% !important;
        right: auto;
        transform: translate(-50%, -50%);
        border-radius: 8px;
    }
}

.center-small-modal {
    &::part(content) {
        border-radius: 8px;
        height: 220px !important;
        width: 25% !important;
        right: auto;
        transform: translate(-50%, -50%);
    }
}

// These should not be here
.size-10 {
    font-size: 10px;
}
.size-14 {
    font-size: 14px;
}
.size-18 {
    font-size: 18px;
}

// Responsive partial
@media only screen and (min-width: 992px) {
    // tryout css grid

    // login Component
    .login-form-wrapper {
        max-width: 60%;
        left: 19%;
    }
}
