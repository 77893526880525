app-time-interval-from,
app-time-picker,
app-time-interval-to,
app-compact-ks-question,
app-standart-ks-question,
app-activity,
app-contacts,
app-products,
ionic-selectable,
app-change-project-status,
app-change-custom-project-status,
app-materials {
    width: 100%;
}

app-overview-modal {
    -webkit-transform: translateZ(0px);
}

pre {
    white-space: pre-wrap;
    font-size: inherit;
    font-family: inherit;
}

// Fab button
.fab {
    width: 70px !important;
    height: 70px !important;
}

.fab-select {
    overflow: visible;
    position: relative;
    contain: layout;
    --background: var(--vibrant-green);
}

.fab-option-create-form {
    position: absolute;
    top: 7px;
    right: 54px;
    color: white;
    line-height: 24px;
    padding: 5px 5px;
    border-radius: 4px;
    width: 500px;
    text-align: right;
}

.fab-option-create-card {
    position: absolute;
    top: 156px;
    right: 54px;
    color: white;
    line-height: 24px;
    padding: 5px 5px;
    border-radius: 4px;
    width: 500px;
    text-align: right;
}

.fab-option-check-in {
    position: absolute;
    top: 59px;
    right: 54px;
    color: white;
    line-height: 24px;
    padding: 5px 5px;
    border-radius: 4px;
    width: 500px;
    text-align: right;
}

.fab-option-post {
    position: absolute;
    top: 108px;
    right: 54px;
    color: white;
    line-height: 24px;
    padding: 5px 5px;
    border-radius: 4px;
    width: 500px;
    text-align: right;
}

.fab-options-wrapper {
    margin-left: 6px;
    margin-bottom: 85px;
}

ion-picker-column > div > button {
    font-size: 16px !important;
    touch-action: none;
}

ion-datetime ion-picker {
    touch-action: none;
}

.apacta-logo {
    padding-top: 4px;
}

ion-title {
    text-align: center;
}

.center-align-buttons .action-sheet-button-inner.sc-ion-action-sheet-md {
    justify-content: center;
}

// app. css
.menu-icons {
    font-size: 33px;
    padding-left: 7px;
}

.filters {
    display: flex;
    flex-direction: row;
    flex-wrap: unset;
    justify-content: center;
    list-style: none;
    padding-left: 0;
    text-align: center;
    color: black;
    font-size: 12px;
}

.filters > li {
    background-color: white;
    border: 1px solid var(--ion-color-primary);
    padding: 2px 8px;
}

.filters > li.first-filter {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.filters > li.middle-filter {
    border-right: none;
    border-left: none;
}

.filters > li.last-filter {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.filters > li.selected-filter {
    color: white;
    background-color: var(--ion-color-primary);
}

// Forms

.p-t-15 {
    padding-top: 15px !important;
}

.form-field-label {
    color: #000c !important;
    font-size: 21px !important;
}

.form-field {
    font-size: 21px !important;
}

.field-wrapper {
    margin-top: 15px;
}

.input-field-label {
    font-size: 27px !important;
}

.text-input-field {
    text-align: right;
}

.no-padding {
    padding: 0;
}

.icon-barcode {
    float: right;
    font-size: 30px;
}

.icon-barcode-hidden {
    visibility: hidden;
}

.title-products {
    padding-top: 10px;
    padding-bottom: 10px;
}

.title_text {
    position: absolute;
    left: 40%;
}

.cancel-products {
    box-sizing: border-box;
    color: rgb(66, 66, 66);
    font-family: "Roboto", "Helvetica Neue", sans-serif;
    font-kerning: auto;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.833333px;
    overflow-wrap: break-word;
    pointer-events: auto;
    text-align: center;
    text-rendering: optimizelegibility;
    text-transform: uppercase;
    visibility: visible;
    white-space: nowrap;
    -moz-osx-font-smoothing: grayscale;
    -moz-text-size-adjust: none;
    line-height: 2;
    position: absolute;
    left: 14px;
}

app-loading {
    transition: all ease 0.75s;
}

.modal-fullscreen {
    &::part(content) {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
    }
}

.form-input-icon {
    position: relative;
    top: 3px;
}

// TOAST MESSAGES
.txt-center {
    text-align: center;
}

.txt-right {
    text-align: right;
}

.w-100 {
    width: 100%;
}

// LANGS
// Great Ben
#gb {
    background-image: url(//../flags/4x3/gb.svg);
    background-repeat: no-repeat;
    background-size: 50px;
    background-position-x: 65px;
    background-position-y: center;
}

#gb::before {
    content: "English";
    position: absolute;
    top: 11px;
    left: 120px;
    font-size: 20px;
}
// Danish
#dk {
    background-image: url(/../flags/4x3/dk.svg);
    background-repeat: no-repeat;
    background-size: 50px;
    background-position-x: 65px;
    background-position-y: center;
}

#dk::before {
    content: "Dansk";
    position: absolute;
    top: 11px;
    left: 120px;
    font-size: 20px;
}
// German
#de {
    background-image: url(/../flags/4x3/de.svg);
    background-repeat: no-repeat;
    background-size: 50px;
    background-position-x: 65px;
    background-position-y: center;
}

#de::before {
    content: "Deutsche";
    position: absolute;
    top: 11px;
    left: 120px;
    font-size: 20px;
}
// Norway
#no {
    background-image: url(/../flags/4x3/no.svg);
    background-repeat: no-repeat;
    background-size: 50px;
    background-position-x: 65px;
    background-position-y: center;
}
#no::before {
    content: "Norsk";
    position: absolute;
    top: 11px;
    left: 120px;
    font-size: 20px;
}
// Romania
#ro {
    background-image: url(/../flags/4x3/ro.svg);
    background-repeat: no-repeat;
    background-size: 50px;
    background-position-x: 65px;
    background-position-y: center;
}
#ro::before {
    content: "Romania";
    position: absolute;
    top: 11px;
    left: 120px;
    font-size: 20px;
}
// Polish
#pl {
    background-image: url(/../flags/4x3/pl.svg);
    background-repeat: no-repeat;
    background-size: 50px;
    background-position-x: 65px;
    background-position-y: center;
}
#pl::before {
    content: "Polish";
    position: absolute;
    top: 11px;
    left: 120px;
    font-size: 20px;
}
// RUssian
#ru {
    background-image: url(../flags/4x3/ru.svg);
    background-repeat: no-repeat;
    background-size: 50px;
    background-position-x: 65px;
    background-position-y: center;
}

#ru::before {
    content: "Russian";
    position: absolute;
    top: 11px;
    left: 120px;
    font-size: 20px;
}
// RUssian
#ru {
    background-image: url(/../flags/4x3/ru.svg);
    background-repeat: no-repeat;
    background-size: 50px;
    background-position-x: 65px;
    background-position-y: center;
}

#ru::before {
    content: "Russian";
    position: absolute;
    top: 11px;
    left: 120px;
    font-size: 20px;
}

.color-danger {
    color: #f04141 !important;
}

.event-alert {
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    margin-top: 20px;
    --padding-top: 0;
    --padding-bottom: 0px;
}

.event-alert-label {
    font-size: 18px;
    top: 2px;
    position: relative;
}

// Flags - for new company action sheet
// src\app\company\new-company
.en {
    background-image: url(/assets/flags/4x3/gb.svg) !important;
    background-repeat: no-repeat !important;
    background-size: 46px !important;
    background-position-x: 18px !important;
    background-position-y: center !important;
}

.dk {
    background-image: url(/assets/flags/4x3/dk.svg) !important;
    background-repeat: no-repeat !important;
    background-size: 46px !important;
    background-position-x: 18px !important;
    background-position-y: center !important;
}

.de {
    background-image: url(/assets/flags/4x3/de.svg) !important;
    background-repeat: no-repeat !important;
    background-size: 46px !important;
    background-position-x: 18px !important;
    background-position-y: center !important;
}

.no {
    background-image: url(/assets/flags/4x3/no.svg) !important;
    background-repeat: no-repeat !important;
    background-size: 46px !important;
    background-position-x: 18px !important;
    background-position-y: center !important;
}

.ru {
    background-image: url(/assets/flags/4x3/ru.svg) !important;
    background-repeat: no-repeat !important;
    background-size: 46px !important;
    background-position-x: 18px !important;
    background-position-y: center !important;
}

.ro {
    background-image: url(/assets/flags/4x3/ro.svg) !important;
    background-repeat: no-repeat !important;
    background-size: 46px !important;
    background-position-x: 18px !important;
    background-position-y: center !important;
}

.pl {
    background-image: url(/assets/flags/4x3/pl.svg) !important;
    background-repeat: no-repeat !important;
    background-size: 46px !important;
    background-position-x: 18px !important;
    background-position-y: center !important;
}

// iOS flags
.en-ios {
    background-image: url(/assets/flags/4x3/gb.svg) !important;
    background-repeat: no-repeat !important;
    background-size: 46px !important;
    background-position-x: 18px !important;
    background-position-y: center !important;
}

.dk-ios {
    background-image: url(/assets/flags/4x3/dk.svg) !important;
    background-repeat: no-repeat !important;
    background-size: 46px !important;
    background-position-x: 18px !important;
    background-position-y: center !important;
}

.de-ios {
    background-image: url(/assets/flags/4x3/de.svg) !important;
    background-repeat: no-repeat !important;
    background-size: 46px !important;
    background-position-x: 18px !important;
    background-position-y: center !important;
}

.no-ios {
    background-image: url(/assets/flags/4x3/no.svg) !important;
    background-repeat: no-repeat !important;
    background-size: 46px !important;
    background-position-x: 18px !important;
    background-position-y: center !important;
}

.ru-ios {
    background-image: url(/assets/flags/4x3/ru.svg) !important;
    background-repeat: no-repeat !important;
    background-size: 46px !important;
    background-position-x: 18px !important;
    background-position-y: center !important;
}

.ro-ios {
    background-image: url(/assets/flags/4x3/ro.svg) !important;
    background-repeat: no-repeat !important;
    background-size: 46px !important;
    background-position-x: 18px !important;
    background-position-y: center !important;
}

.pl-ios {
    background-image: url(/assets/flags/4x3/pl.svg) !important;
    background-repeat: no-repeat !important;
    background-size: 46px !important;
    background-position-x: 18px !important;
    background-position-y: center !important;
}

ion-item-divider {
    background-color: white;
}

ion-modal::part(content),
app-driving-modal::part(content) {
    -webkit-transform: translateZ(0);
}

.border-top-none {
    border-top: none !important;
}

ion-grid ion-col.pad-1-l-r {
    padding-left: 1px;
    padding-right: 1px;
}

.border-rad-top {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.border-rad-bot {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

ion-col.pad-top-1 {
    padding-top: 1px;
}

ion-col.pad-bot-1 {
    padding-bottom: 1px;
}

.notebook-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #115e59;
    color: #fff;
    padding: 15px 10px;
    font-size: 20px;
}

.notebook-icon {
    font-size: 24px;
    vertical-align: text-top;
    padding-right: 5px;
}

ion-header {
    padding-top: var(--ion-safe-area-top, 0) !important;
}

.alert-button-close {
    background-image: url(/assets/img/close-circle-outline.svg);
    width: 32px;
    min-width: 32px !important;
    border: 0 !important;
    height: 32px !important;
    position: absolute !important;
    padding: 0 !important;
    top: 8px;
    right: 0;
}

swiper-container {
    --swiper-pagination-bullet-inactive-color: var(--ion-text-color-step-800, #cccccc);
    --swiper-pagination-color: var(--ion-color-primary, #0054e9);
    --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
    --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
    --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
}

swiper-slide {
    display: flex;
    position: relative;

    flex-direction: column;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    font-size: 18px;

    text-align: center;
    box-sizing: border-box;
}

swiper-slide img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
}
