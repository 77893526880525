.wrap {
    padding: 30px;
}

.close {
    width: 20px;
    height: 20px;
}

.h-37 {
    height: 37px;
}

.border-top {
    border-top: 1px solid gainsboro;
}

.border {
    border-bottom: 1px solid gainsboro;
}

.dropdown {
    right: 0px;
    top: -12px;
}

.table {
    overflow-y: scroll;
}
