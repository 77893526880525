// btn

.btn {
    height: 36px;
    margin: 0 5px 0 5px;
    border-radius: 18px;
    --border-radius: 18px;
    overflow: hidden;

    &:focus {
        outline: none;
    }
}

.border-rad-segment {
    border-radius: 30px;
}

.border-rad {
    border-radius: 14px;
}

.btn-success {
    color: var(--white);
    background: var(--vibrant-green);
}

.btn-danger {
    color: var(--white);
    background: var(--pastel-red);
}

.btn-success-outline {
    background-color: var(--white);
    color: var(--vibrant-green);
    border: 2px solid var(--vibrant-green);
}

.btn-primary {
    color: var(--white);
    background: var(--active-blue);
}

.close-button {
    color: var(--gray);
    font-size: 25px;
    margin: 10px;
    cursor: pointer;
}

.btn-var-white {
    background-color: var(--white);
    border: 2px solid var(--pale-blue);
    color: var(--gray);
}

.btn-filter {
    background-color: var(--white);
    color: var(--gray);
}

.btn-pad-20 {
    padding-right: 20px;
    padding-left: 20px;
}

.btn-pad-10 {
    padding-right: 10px;
    padding-left: 10px;
}

.btn-pad-5 {
    padding-right: 5px;
    padding-left: 5px;
}

.btn-icon-pad-left {
    padding-left: 36px;
}

.btn-icon-pad-right-30 {
    padding-right: 30px;
}
