@mixin height {
    @for $i from 0 through 40 {
        .height-#{ $i } {
            display: inline-block;
            height: #{$i}px;
        }
    }
}

@mixin l-h {
    @for $i from 0 through 60 {
        .line-height-#{ $i } {
            display: inline-block;
            line-height: #{$i}px !important;
            vertical-align: middle;
        }
    }
}

// font-size-X   x -> [6-36]px
@mixin f-s {
    @for $i from 5 through 36 {
        .font-size-#{ $i } {
            font-size: #{$i}px !important;
        }
    }
}

@include height;
@include l-h;
@include f-s;

// _text
.clr-black {
    color: var(--black);
}

.clr-white {
    color: var(--white);
}

.clr-orange {
    color: var(--tangerine) !important;
}

.clr-gray {
    color: var(--gray) !important;
}

.clr-gunmetal {
    color: var(--gun-metal) !important;
}

.clr-battleship {
    color: var(--battleship-grey) !important;
}

.clr-gray {
    color: var(--gray) !important;
}

.clr-pale-blue {
    color: var(--pale-blue) !important;
}

.clr-success-icon {
    color: var(--vibrant-green) !important;
}

.clr-blue {
    color: var(--blue) !important;
}

.clr-link-blue {
    color: var(--app-link-blue) !important;
}

.clr-pale-blue {
    color: var(--pale-blue) !important;
}

.clr-active-blue {
    color: var(--active-blue) !important;
}

.clr-red,
.clr-danger-icon {
    color: var(--pastel-red) !important;
}

.clr-orange {
    color: var(--tangerine) !important;
}

.clr-green {
    color: var(--vibrant-green) !important;
}

.bg-color-green {
    background-color: rgba(0, 203, 0, 0.2) !important;
}

.bg-color-red {
    background-color: rgba(246, 66, 66, 0.2) !important;
}

.bg-color-orange {
    background-color: rgba(255, 144, 0, 0.2) !important;
}

.clr-charcoal-grey {
    color: var(--charcoal-grey);
}

.clr-menu-group-gray {
    color: var(--menu-group-label);
}

.txt-cap {
    text-transform: capitalize;
}

.txt-upper {
    text-transform: uppercase;
}

.txt-left {
    text-align: left;
}

.txt-right {
    text-align: right;
    --text-align: right;
}

.txt-center {
    text-align: center;
    --text-align: center;
}

.txt-bold-500 {
    font-weight: 500;
}

.txt-bold {
    font-weight: 900;
}

.txt-normal {
    font-weight: 400;
}

.white-space-normal {
    white-space: normal;
}

.line-height-58 {
    display: inline-block;
    line-height: 58px;
}

.no-text-decoration {
    text-decoration: none;
}

.center-orientated {
    justify-content: center;
}

.right-orientated {
    flex-flow: row-reverse;
}

.no-user-select {
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
}

.pre-wrap {
    white-space: pre-wrap;
}

.overflow-hidden {
    overflow: hidden;
}

.word-break-on-letter {
    word-break: break-all;
}
