.modal-close-icon {
    right: -50px;
    top: -50px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.modal-icon {
    border-radius: 10px;
    padding: 4px;
}

.modal-txt {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: var(--gun-metal);
}

.modal-container {
    background: var(--dark-content);
}

.modal-head {
    font-size: 24px;
    font-weight: 500;
    @extend .modal-txt;
}

.modal-sub-head {
    font-size: 14px;
    line-height: 4;
    @extend .modal-txt;
}

.modal-warning {
    .icon {
        width: 20px;
        height: 20px;
    }

    .war-header {
        font-size: 14px;
        font-weight: 500;
        color: var(--charcoal-grey);
        @extend .modal-txt;
    }

    .war-main {
        width: 100%;
        font-size: 14px;
        color: var(--gun-metal);
        @extend .modal-txt;
    }

    pre {
        white-space: pre-line;
    }

    border-radius: 8px;
    background-color: var(--ice-blue);
}

.modal-input {
    border: solid 1px var(--ice-blue);
    border-radius: 8px;
    margin-bottom: 15px;
    background-color: var(--pale-grey);
}

.modal-footer {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;
}

// INPUTS
.input-label {
    font-size: 14px;
    font-weight: 500;
    color: var(--gun-metal);
    margin-bottom: 10px;

    &.font-bold {
        font-weight: bold;
    }
}

.input-sub-label {
    font-size: 12px;
    color: var(--gray);
    margin-bottom: 10px;
}

.form-input {
    border-radius: 8px;
    margin-bottom: 15px;
    background-color: var(--pale-grey);
    border: 1px solid var(--pale-grey);
    font-size: 13px;
    --padding-start: 15px;
    --color: var(--gun-metal);
}

.hours-small-input {
    border-radius: 8px;
    background-color: var(--pale-grey);
    border: 1px solid var(--pale-grey);
    font-size: 12px;
    --color: var(--gun-metal);
    min-width: 44px;
    text-align: center;
    flex: 0;
    --padding-start: 0px !important;
    margin-right: 10px;
    margin-left: 10px;
    --padding-inline-start: 0px !important;

    input {
        --padding-start: 0px !important;
        --padding-inline-start: 0px !important;
    }
}

.sub-modal-input {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 15px;
    background-color: var(--pale-grey);
    border: 1px solid var(--pale-grey);
    font-size: 13px;
    --padding-start: 15px;
    --color: var(--gun-metal);
}

.sub-modal-input:focus {
    border: solid 1px var(--blue);
}

.input-error {
    border: 1px solid red;
    border-color: red !important;
}

.input-error-message {
    color: red;
    font-size: 12px;
    margin: 5px;
}

.required-icon {
    color: var(--blue);
    font-size: 19px;
}

div ion-input.no-border {
    border: solid 1px var(--blue);

    &.input-error {
        border: 1px solid red;
    }
}

.event-modal-alert::part(content) {
    width: 300px;
    height: 250px !important;
    margin: auto;
    box-shadow: 0px 2px 15px black;
    border-radius: 5px;
    position: fixed;
    right: calc(50% - 150px);
}

// buttons
.border-1-gray {
    border: 1px solid var(--gray);
}

.copy-event-modal::part(content) {
    height: 532px !important;
    border-radius: 12px;
    position: fixed;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    z-index: 20002;
    opacity: 1 !important;
    transform: none !important;
}
