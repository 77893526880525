// Shared

$degreesSign: "deg";

@mixin transform-rotate-mixin {
    @for $i from 1 through 23 {
        .rotate-#{ $i*15 } {
            transform: rotate(#{$i * 15}#{$degreesSign});
        }
    }
}

@include transform-rotate-mixin;

dropdown {
    width: 100%;
}

.center-viewpage-loader {
    display: inline-block;
    position: relative;
    margin-left: -6%;
    margin-right: -6%;
    width: calc(112%);
    padding: 0 6%;

    app-loading {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}

.divider {
    margin-right: 15px;
    margin-left: 15px;
    border-right: 1px solid var(--pale-blue);
    height: 80%;
    display: inline-block;
    padding-top: 24px;
}

.blue-btn {
    background-color: #236ced;
    color: white;
}

ion-input.row-of-options-item {
    --padding-start: 20px !important;
    --padding-end: 20px !important;
    --padding-bottom: 8px;
    --padding-top: 8px;
    background: var(--pale-grey) !important;
    border-radius: 8px;
    border: 1px solid var(--gray) !important;
    font-size: 14px !important;
}

dropdown ion-input.row-of-options-item {
    --padding-start: 20px;
    --padding-end: 20px;
    --padding-bottom: 8px;
    --padding-top: 8px;
    background: var(--pale-grey);
    border-radius: 8px;
    border: 1px solid var(--gray);
    font-size: 14px;
}

files-chooser.high-picker {
    .files-container {
        min-height: 160px !important;
    }
}

.width-100 {
    width: 100px !important;
    max-width: 100px !important;
    min-width: 100px !important;
}

.width-200 {
    width: 200px !important;
    max-width: 200px !important;
    min-width: 120px !important;
}

.width-150 {
    width: 150px !important;
    max-width: 150px !important;
    min-width: 100px !important;
}

.width-120 {
    width: 120px !important;
    max-width: 120px !important;
    min-width: 100px !important;
}

.language-modal::part(content),
.popup-modal::part(content) {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    width: 650px !important;
    border-radius: 12px;
    z-index: 20002;
    opacity: 1 !important;
    transform: none !important;
}

.countries-modal::part(content) {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    width: 500px !important;
    height: 320px !important;
    border-radius: 12px;
    z-index: 20002;
    opacity: 1 !important;
    transform: none !important;
}

.popup-modal::part(content) {
    height: 360px !important;
}

.language-modal::part(content) {
    height: 290px !important;
}

ion-modal.create-entity-modal {
    background: rgba(0, 0, 0, 0.3);

    &.no-translation {
        &::part(content) {
            -webkit-transform: translate(0, 0) !important;
            transform: translate(0, 0) !important;
        }
    }

    &::part(content) {
        display: block;
        width: 35% !important;
        -webkit-transform: translate3d(0, 0px, 0);
        transform: translate3d(0, 0px, 0);
        opacity: 1;
    }
}

.warning-icon {
    color: var(--red) !important;
    --color: var(--red) !important;
}

.header {
    margin-top: 100px;
}

.head-icon {
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    border-radius: 10px;
    display: inline-block;
    vertical-align: middle;
    padding: 8px;
    opacity: 1;
    margin-bottom: 6px;
    background-color: rgba(0, 137, 255, 0.1);
}

.input-error-message {
    color: red;
    font-size: 10px;
    margin: 5px;
}

.action-bar {
    border-radius: 8px;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.04);
    background-color: var(--dark-content);
    padding: 0 20px;
}

.action-bar-icon {
    margin-top: 3px;
    font-size: 30px;
    border-radius: 2px;
    color: var(--gray);
    cursor: pointer;
}

.action-bar-label {
    margin-top: 3px;
    padding: 10px 0;
    font-size: 14px;
    border-radius: 2px;
    color: var(--gray);
    cursor: pointer;
}

.action-bar-icon-small {
    margin-bottom: 5px;
    font-size: 20px;
    color: var(--gray);
    cursor: pointer;
}

.action-bar-icon-small-label {
    margin-bottom: -5px;
    font-size: 20px;
    color: var(--gray);
    cursor: pointer;
}

.empty-state-title {
    margin-top: 50px;
    font-size: 14px;
    font-weight: 500;
    color: var(--gun-metal);
    text-align: center;
}

.empty-state-info {
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: var(--gray);
    margin-bottom: 50px;
}

.view-project-create-options-btn {
    left: 8px !important;
}

.ul-no-bul {
    list-style: none;
}

.active-page {
    background-color: var(--very-light-blue) !important;
    color: var(--blue) !important;
}

.ng-select .ng-select-container {
    border: none !important;
    border-radius: 16px;
    margin-bottom: 15px;
    background-color: var(--pale-grey);
    font-size: 13px;
}

.ng-select.ng-select-opened > .ng-select-container {
    background: var(--pale-grey);
}

.ng-select .ng-arrow-wrapper {
    width: 0;
    padding-right: 0;
}

.ng-dropdown-panel.ng-select-bottom {
    border: none !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: var(--pale-grey);
}

.box-shadow {
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.04);
}

// POSITIONS
.pos-abs {
    position: absolute;
}

.pos-rel {
    position: relative;
}

.op-1 {
    opacity: 1 !important;
}

.op-0 {
    opacity: 0;
}

.crs-pointer {
    cursor: pointer;
    --cursor: pointer;
}

.crs-default {
    cursor: default;
    --cursor: default;
}

.crs-no-selection {
    pointer-events: none;
    user-select: none;
    cursor: default;
}

ion-input.pointer-inputs {
    input {
        cursor: pointer;
    }
}

.border-rad-14 {
    border-radius: 14px;
}

.border-rad-8 {
    border-radius: 8px;
}

.z-ind-1000 {
    z-index: 1000 !important;
}

.h-auto {
    height: auto;
}

.vert-align-mid {
    vertical-align: middle;
}

.vert-align-top {
    vertical-align: top !important;
}

.vert-align-bot {
    vertical-align: bottom;
}

.hidden {
    display: none !important;
}

.no-select {
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
}

.move-to-right {
    margin-left: auto;
    margin-top: 0;
    margin-bottom: 0;
}

.transparent {
    background-color: transparent !important;
}

.no-padding {
    padding: 0 !important;
}

.flipped {
    transform: rotate(-180deg);
}

.sticky-dd .dropdown {
    top: 0 !important;
}

.transparent-bg {
    background: transparent !important;
    --background: transparent !important;
}

.inline-block {
    display: inline-block;
}

.dot-menu-table-template {
    position: absolute;
    top: 0;
    right: 4px;
}

ion-input {
    line-height: 1.29;
    color: var(--gun-metal);
}

.line {
    height: 1px;
    margin-top: 20px;
    background: #f0f2f4;
}

.section-title {
    margin-top: 50px;
    font-size: 18px;
    color: var(--gun-metal);
}

.section {
    margin-top: 30px;
    border-radius: 14px;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.04);
    background-color: #ffffff;
    padding: 0px !important;
}

.section-box {
    border: 1px solid var(--section-border);
    padding: 10px 20px 20px 20px;
}

.section-box-title {
    margin-top: 20px;
    font-size: 16px;
    color: var(--gun-metal);
}

.input-fixed-label {
    font-size: 12px;
    letter-spacing: 0.14px;
    color: #abb2be;
}

.create-button {
    float: right;
}

.overflow-inherit {
    overflow: inherit;
}

.break-spaces {
    white-space: break-spaces;
}

.nowrap-spaces {
    white-space: nowrap;
}

.display-block {
    display: block !important;
}

.dropdown-wrapping-div {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);

    ion-label {
        line-height: 48px;
    }
}

.round-color {
    display: inline-block;
    width: 22px;
    height: 22px;
    margin-right: 10px;
    border-radius: 50%;
}

.primary-white-button {
    border: solid 1px var(--pale-blue);
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--gunmetal);
    border-radius: 8px;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.04);
    background-color: var(--white-bg);
    margin: 0;
    padding: 0 10px 0 28px !important;

    ion-icon {
        left: 7px !important;
        font-size: 20px !important;
        top: 6px !important;
    }
}

.secondary-modal-button {
    border-radius: 18px;
    border: solid 1px var(--pale-blue);
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #abb2be;
}

ul.dropdown-items {
    li {
        border: solid 1px var(--ice-blue);
        background-color: var(--dark-content);
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06);
        cursor: pointer;

        &:hover {
            background-color: rgba(35, 108, 237, 0.1);
        }

        .dropdown-image {
            height: 46px;
            width: 61px;
            background: #ffffff;

            &.alternative {
                position: relative;

                ion-icon {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    font-size: 46px;
                }
            }
        }

        .dropdown-data-section {
            max-width: calc(100% - 100px);
            line-height: 46px;

            .restricted-length-text {
                max-width: 100%;
                line-height: 22px;
                font-size: 12px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .not-restricted-length-text {
                max-width: 100%;
                line-height: 22px;
                font-size: 12px;
            }
        }
    }
}

.grid-item-line-data {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;

    &:not(:last-of-type) {
        border-bottom: 1px solid var(--ice-blue);
    }

    .grid-item-lin-data-section {
        display: inline-block;
        padding: 0;
        line-height: 22px;

        &.left-side {
            width: auto;
            max-width: 200px;
            margin: auto 0;
        }

        &.right-side {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: right;
        }
    }
}

.vis-hidden {
    visibility: hidden;
}

.white-segment {
    margin-top: 20px;
    border-radius: 14px;
    --background: var(--dark-content);
    background: var(--dark-content);
    padding: 15px 25px;

    .button-wrapper {
        display: block;
        width: 100%;

        button {
            padding: 9px 17px;

            &:focus {
                outline: 0;
            }

            ion-icon {
                font-size: 20px;
                vertical-align: middle;
            }

            span {
                display: inline-block;
                line-height: 20px;
                vertical-align: middle;
            }
        }
    }
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-row-reverse {
    display: flex;
    flex-direction: row-reverse;
}

.flex-1 {
    flex: 1;
}

ion-icon.back-to-list {
    top: 6px;
    left: -40px;
    font-size: 24px;
    color: var(--gun-metal);
}

.visible-overflow {
    overflow: visible !important;
}

.min-width-100 {
    min-width: 100px;
}

.border-radius-8 {
    border-radius: 8px;
}

.max-width-80 {
    max-width: 80%;
}

.no-text-overflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.no-visibility {
    visibility: hidden;
}

.invoice-offer-date-picker.date-range-picker {
    box-shadow: none !important;
    background-color: var(--pale-grey) !important;
    border-radius: 8px !important;
    border: solid 1px var(--gray);
}

.disabled-calendar.date-range-picker {
    background-color: transparent !important;
    box-shadow: none !important;
}

.grid-dot-menu {
    top: 21px;
    z-index: 1;
    right: 17px;
}

.right-0 {
    right: 0;
}

.table-icon-dimensions {
    width: 26px;
    height: 26px;
}

.status-approved {
    border-radius: 50%;
    font-size: 20px;
    padding: 3px;
    background-color: #c0f2d4;
    color: #33c733;
}

.action-bar {
    align-items: center;

    .table-actions {
        padding-top: 5px;
        padding-bottom: 5px;

        &:nth-of-type(1) {
            padding-left: 5px;
            padding-right: 28px;
        }

        &:nth-of-type(2) {
            padding-left: 16px;
            padding-right: 14px;
        }

        &:nth-child(3) {
            display: inline-block;
            padding-left: 19px;
            padding-right: 6px;
        }
    }

    & > .ion-align-self-center {
        & ~ .table-actions {
            &:nth-of-type(2) {
                padding-left: 5px !important;
                padding-right: 28px !important;
            }

            &:nth-of-type(3) {
                padding-left: 16px !important;
                padding-right: 14px !important;
            }

            &:nth-child(4) {
                display: inline-block;
                padding-left: 19px !important;
                padding-right: 6px !important;
            }
        }
    }
}

.occp-warning {
    color: orange !important;
    position: relative;
    left: 9px;
    font-size: 22px !important;
}

.no-box-shadow {
    box-shadow: none;
}

.no-color {
    --background: var(--white);
    background: var(--white);
    color: var(--black);
    border: 2px solid #80808085 !important;
}

.dot-menu-template {
    position: absolute;
    right: 10px;
    top: 3px;
}

.not-allowed {
    cursor: not-allowed;
}

.required-field-bracet {
    &:after {
        position: absolute;
        left: -17px;
        top: 7px;
        content: "(*)";
        color: var(--active-blue);
        font-size: 14px;
    }

    &.time:after {
        top: 11px !important;
    }
}

.opacity-5 {
    opacity: 0.5;
}

/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* Animate an item that has been dropped. */
.cdk-drag-animating {
    transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

.sc-ion-popover-md-h {
    --width: auto !important;
}

ion-item.item-disabled {
    opacity: 1 !important;

    & > *:not(help) {
        opacity: 0.3;
    }
}

.cdk-placeholder {
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.3);
    border: 2px solid #80808085 !important;
}
