// http://ionicframework.com/docs/theming/
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "assets/css/mobile.scss";
@import "assets/css/control-panel.scss";
@import "assets/css/breakpoints.scss";

p,
div {
    margin: 0;
}

body * {
    font-family: "Roboto", sans-serif;
}

// Popover menu class override should on global level. Used only in responsive mode
.submenu-popover .popover-content {
    margin: 0 0 0 44px;
}
