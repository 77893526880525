// _calendar
.apacta-calendar {
    $ion-color-primary: var(--very-light-green) !important;

    ion-button.in-toolbar {
        span {
            color: var(--charcoal-grey) !important;
        }
    }

    .month-title {
        font-size: 16px !important;
        font-weight: bold !important;
        color: var(--charcoal-grey) !important;
    }

    button.days-btn {
        p {
            color: var(--gun-metal) !important;
        }
    }

    .on-selected p {
        color: var(--white) !important;
    }

    .on-selected {
        border-radius: 22px !important;
        background-color: var(--vibrant-green) !important;
    }

    .startSelection {
        border-radius: 4px !important;
    }

    .between {
        border-radius: 4px !important;
        background-color: var(--very-light-green) !important;
    }

    .startSelection:before {
        background-color: var(--very-light-green) !important;
    }
    .startSelection:after {
        background-color: var(--white);
        opacity: 0.25;
    }

    .endSelection:before {
        background-color: var(--very-light-green) !important;
    }

    .between button.days-btn {
        background-color: var(--very-light-green) !important;
        p {
            color: var(--gray) !important;
        }
    }

    .endSelection button.days-btn {
        background-color: var(--vibrant-green) !important;
        color: #fff;
    }
}
