// _ionic
ion-title {
    font-size: 28px;
}

ion-content {
    --background: var(--white-bg);
}

ion-checkbox {
    margin-top: 0;
}

.cp-calendar {
    $ion-color-primary: var(--blue) !important;

    ion-calendar-month {
        margin-top: 4px !important;
    }

    .week-toolbar.in-toolbar {
        height: 30px !important;
        margin-top: -8px;
    }

    .month-picker {
        margin: 0 !important;

        .month-packer-item {
            height: 30px !important;
            padding: 0 !important;

            button {
                font-size: 11px !important;
            }
        }
    }

    ion-buttons {
        margin-right: 40px;
    }

    ul.week-title {
        height: 24px !important;
        padding: 0 !important;

        li {
            font-size: 12px;
            color: black;
            font-weight: bold;
        }
    }

    .forward,
    .back {
        ion-icon {
            width: 14px !important;
            height: 14px !important;
        }
    }

    .days-box {
        padding: 0 !important;

        .days {
            display: flex !important;
            flex-direction: column !important;
            justify-content: center !important;
            margin-bottom: 0 !important;

            .days-btn {
                width: 27px !important;
                height: 27px !important;
            }
        }
    }

    .switch-btn {
        font-size: 14px !important;
        font-weight: 500;

        ion-icon.arrow-dropdown {
            display: none;
        }
    }

    button.days-btn {
        width: 100% !important;

        p {
            font-size: 13px !important;
        }
    }

    .month-title {
        font-size: 16px !important;
        font-weight: bold !important;
        color: #2c3135 !important;
    }

    .on-selected p {
        color: var(--white) !important;
    }

    .on-selected {
        border-radius: 22px !important;
        background-color: var(--blue) !important;
    }

    .today {
        &.on-selected p {
            color: var(--white) !important;
        }

        p {
            color: var(--blue) !important;
        }
    }
}

ion-item {
    --background-hover: none;
    --color-activated: none;
    --color-focused: none;
    cursor: pointer;
}

.ngx-file-drop__drop-zone {
    border: none !important;
}

.small-checkbox {
    --size: 16px;
    --border-radius: 5px;
    --border-width: 1px;
    --border-color: var(--pale-blue);
    --checkmark-width: 3px;
    --background-checked: var(--blue);
    --border-color-checked: var(--blue);
}

.medium-chekcbox {
    --size: 20px;
    --border-radius: 5px;
    --border-width: 1px;
    --border-color: var(--pale-blue);
    --checkmark-width: 3px;
    --background-checked: var(--blue);
    --border-color-checked: var(--blue);
}

ion-popover {
    &.bg-gun-metal div.popover-content {
        margin: 0;
    }

    ion-list {
        ion-item {
            --min-height: 35px;

            ion-icon {
                &[slot="start"] {
                    margin-inline-end: 10px;
                }

                &[slot] {
                    margin-top: 8px;
                    margin-bottom: 8px;
                }
            }
        }
    }

    .popover-viewport ion-list ion-item ion-label {
        font-size: 12px !important;
        size: 12px;
        font-family: "Roboto-Medium";
        color: var(--gun-metal);

        &.active {
            color: var(--blue);
        }
    }
}

.popover-content {
    border-radius: 10px !important;
    margin: 8px;
}

ion-popover.calendar-form > .popover-wrapper .popover-content {
    --box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06) !important;
    margin: 0 !important;
}

.--pad-start {
    --padding-start: 0;
}

.--pad-bot-10 {
    --padding-bottom: 10px;
}

.--inner-pad-0 {
    --inner-padding-start: 0 !important;
    --inner-padding-end: 0 !important;
}

.--pad-top-5 {
    --padding-top: 5px;
}

ion-modal::part(content) {
    height: 100% !important;
    position: absolute;
    right: 0;
}

.data-table-modal {
    --height: auto;
    width: 100% !important;

    .ion-page {
        position: relative;
        display: block;
        contain: content;

        .grid-size {
            overflow: auto;
        }
    }
}

ion-footer {
    &:before {
        content: none !important;
    }
}

ion-modal.data-table-modal::part(content) {
    height: auto !important;
    width: 90% !important;
    position: unset;
    border-radius: 8px;
    --background: var(--dark-content);
    background: var(--dark-content);

    ion-toolbar {
        --background: var(--dark-content);
        background: var(--dark-content);
    }
}

ion-input {
    &.ellipsed {
        text-overflow: ellipsis;
    }
}

.alert-radio-label.sc-ion-alert-md {
    white-space: break-spaces !important;
}

.sc-ion-alert-md-h {
    --max-width: 500px !important;
}

.has-focus {
    pointer-events: all !important;
}

.focus {
    border-color: var(--blue) !important;
}

.content-auto-wrap {
    --width: auto;
    --display: inline-block;
}

ion-reorder {
    ion-icon {
        margin-top: 14px;
    }
}

.w-60 {
    width: 60% !important;
    --width: 60% !important;
    max-width: 240px !important;
}

.rounder-bordered-icons {
    font-size: 14px;
    padding: 4px;
    border: 1px solid #979797;
    border-radius: 50%;
}

.toggle {
    --handle-height: 15px;
    --handle-width: 15px;
    height: 19px;
    width: 32px;
    --background-checked: var(--vibrant-green);
}

.no-padding-start {
    --padding-start: 0 !important;
}

ion-textarea.not-full-height {
    position: relative;
    width: 100%;
    height: 100px;
    border: solid 1px var(--Active-blue);
    border-radius: 8px;
    background-color: var(--pale-grey);
    --padding-start: 10px;
    --padding-end: 10px;
    --padding-bottom: 5px;
    --padding-top: 5px;

    textarea {
        height: 80px;
    }
}

app-form-edit.control-panel-form {
    ion-content {
        height: calc(100% - 56px);
    }
}

.calendar-segment {
}

.ignore-type-number-arrows {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
    }

    input.native-input[type="number"] {
        -moz-appearance: textfield;
    }
}

.reject-reasons {
    .popover-content {
        top: 0 !important;
        left: 0 !important;
        position: relative;
    }

    .popover-wrapper {
        position: relative;
        top: 200px !important;
        left: -119px !important;
    }
}
